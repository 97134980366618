@media (min-width: 576px) {
	.container {
		max-width: unset;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: unset;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: unset;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: unset;
	}
}

@media (min-width: 576px) {

	.container-sm,
	.container {
		max-width: unset;
	}
}

@media (min-width: 768px) {

	.container-md,
	.container-sm,
	.container {
		max-width: unset;
	}
}

@media (min-width: 992px) {

	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: unset;
	}
}

@media (min-width: 1200px) {

	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: unset;
	}
}

button {
	position: relative;
}

button>a:only-child {
	border-radius: inherit;
	color: inherit;
}

button>a:only-child::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	border-radius: inherit;
}


.el-button {
	position: relative;
}

.el-button>span {
	border-radius: inherit;
}

.el-button>span>a:only-child {
	border-radius: inherit;
	color: inherit;
}

.el-button>span>a:only-child::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;

	border-radius: inherit;
}

.el-button-group>.el-dropdown {
	float: right;
}

.el-button-group>.el-dropdown>.el-button {
	border-radius: 0;
	border-right-color: var(--el-button-border-color);
}

.el-button-group>.el-dropdown+.el-dropdown>.el-button {
	border-right-color: var(--el-button-divide-border-color);
}

.el-button-group>.el-dropdown+.el-button {
	border-right-color: var(--el-button-divide-border-color);
}

.el-button-group>.el-dropdown:first-child>.el-button.is-circle {
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
}

.el-button-group>.el-dropdown:last-child>.el-button.is-circle {
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
}

.el-button-group>.el-dropdown:first-child>.el-button.is-round {
	border-top-right-radius: var(--el-border-radius-round);
	border-bottom-right-radius: var(--el-border-radius-round);
}

.el-button-group>.el-dropdown:last-child>.el-button.is-round {
	border-top-left-radius: var(--el-border-radius-round);
	border-bottom-left-radius: var(--el-border-radius-round);
}

.el-dropdown-menu__item {
	position: relative;
}

.el-dropdown-menu__item>a:only-child {
	color: inherit;
}

.el-dropdown-menu__item>a:only-child::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
}

.guigu_dialog_modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 1021;

}
